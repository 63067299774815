.card {
  border-radius: 10px;
  box-shadow: 0px 0px 7px;
  background-color: white;
  padding: 1px;
  margin: 5px;
  width: 90%;
}

#card-page-header {
  width: 90%;
}

#add-card-btn {
  align-self: center;
  transform: translateX(-50%);
}

#your-cards-heading {
  width: 50%;
}

#add-card-form {
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
}
