.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.fills-page {
  width: 100vw;
  height: 100vh;
}

.moved-up-by-height {
  transform: translateY(-100%);
}

.fill-x {
  width: 100%;
}

.text-align-center {
  text-align: center;
}

body {
  background-color: whitesmoke;
}

.exit-button {
  width: 20px;
  height: 20px;
  text-align: center;
  box-shadow: 0 0 2px;
}

.exit-button:hover {
  cursor: pointer;
}
